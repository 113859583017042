import React from 'react'
import './Header.css'
const Header = () => {
  return (
    <>
    
    <div className='header'> 
      <div className='text'>
      <h1>LET US BE YOUR WINGMAN</h1>
      <h2>FIND YOUR PERFECT DISH EVERYTIME.</h2>
      <p>Why settle for guesswork when you can have a menu that’s made just for you? Zique is your personal dining wingman, offering tailored food recommendations based on your unique tastes and preferences. Whether you're craving something new or sticking to your favorites, we make sure every meal hits the spot. With Zique, dining out has never been easier or more delicious.
      </p>
      </div>
    </div>
    </>
  )
}

export default Header
